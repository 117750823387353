<template>
  <div class="clearBox">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      :inline="true"
      label-position="right"
      label-width="108px"
      size="mini"
    >
      <!-- <ayl-main title="基本信息" :isCollapse="false"> -->
      <div class="header">基本信息</div>
      <div class="p24px">
        <el-form-item label="所属公司">
          <el-input v-model="companyName" maxlength="50" disabled></el-input>
        </el-form-item>
        <el-form-item label="设备号" prop="deviceInfoVOIOV.deviceName">
          <el-input
            v-model="form.deviceInfoVOIOV.deviceName"
            maxlength="30"
            placeholder="2~30位以内有效数字"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备厂家" prop="deviceInfoVOIOV.deviceCompany">
          <el-select v-model="form.deviceInfoVOIOV.deviceCompany" placeholder="请选择">
            <el-option
              v-for="(item, idx) in $enums.deviceCompanyType.list"
              :key="idx"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="产品型号" prop="deviceInfoVOIOV.deviceModel">
          <el-input v-model="form.deviceInfoVOIOV.deviceModel" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="设备类型" prop="deviceInfoVOIOV.deviceType">
          <el-select v-model="form.deviceInfoVOIOV.deviceType" placeholder="请选择">
            <el-option
              v-for="(item, idx) in $enums.Operator.list"
              :key="idx"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="应用对象" prop="deviceInfoVOIOV.objectType">
          <el-select
            v-model="form.deviceInfoVOIOV.objectType"
            collapse-tags
            placeholder="请选择"
            @change="onChangeObjectType"
          >
            <el-option
              v-for="(item, idx) in $enums_hw.objectType.list"
              :key="idx"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="协议类型" prop="deviceInfoVOIOV.protocolType">
          <el-select
            v-model="form.deviceInfoVOIOV.protocolType"
            multiple
            collapse-tags
            placeholder="可多选"
            clearable
          >
            <el-option
              v-for="(item, idx) in protocolTypeList"
              :key="idx"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="所属标段" prop="deviceInfoVOIOV.bidId">
          <el-cascader
            v-model="form.deviceInfoVOIOV.bidId"
            placeholder="请选择"
            :options="bidList"
            :show-all-levels="false"
            filterable
            @change="hanldBidId"
          ></el-cascader>
        </el-form-item>

        <div v-if="form.deviceInfoVOIOV.deviceType==$enums.Operator.list[0].value">
          <el-divider></el-divider>
          <el-form-item label="摄像头类型" class="clearMr">
            <el-select
              v-model="pipeNumber"
              multiple
              collapse-tags
              @change="onSelectCameraChange"
              placeholder="可多选"
              clearable
            >
              <el-option
                v-for="(item, idx) in $enums.cameraType.list"
                :key="idx"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <div class="aisle">
            <div v-for="(item, idx) in form.deviceAttachedInfoVOIOVs" :key="idx">
              <div class="aisle-form">
                <div class="aisle-title">{{item.pipeName}}</div>
                <el-form-item
                  label="设备类型"
                  :prop="'deviceAttachedInfoVOIOVs['+idx+'].attachedType'"
                  :rules="{required: true, message: '请选择设备类型', trigger: 'change'}"
                >
                  <el-select v-model="item.attachedType" placeholder="请选择">
                    <el-option
                      v-for="(item, idx) in $enums.DeviceType.list"
                      :key="idx"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="使用状态"
                  :prop="'deviceAttachedInfoVOIOVs['+idx+'].status'"
                  :rules="{required: true, message: '请选择使用状态', trigger: 'change'}"
                >
                  <el-select v-model="item.status" placeholder="请选择">
                    <el-option
                      v-for="(item, idx) in $enums.UseType.list"
                      :key="idx"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="型号"
                  :prop="'deviceAttachedInfoVOIOVs['+idx+'].attachedModel'"
                  :rules="{trigger: 'blur'}"
                >
                  <!--validator: $validator.isContainBlank-->
                  <el-input v-model="item.attachedModel" maxlength="16"></el-input>
                </el-form-item>

                <el-form-item
                  label="生产厂家"
                  :prop="'deviceAttachedInfoVOIOVs['+idx+'].attachedCompany'"
                  :rules="{trigger: 'blur'}"
                >
                  <!--validator: $validator.isContainBlank-->
                  <el-input v-model="item.attachedCompany"></el-input>
                </el-form-item>

                <el-form-item
                  label="安装位置"
                  :prop="'deviceAttachedInfoVOIOVs['+idx+'].installLocation'"
                  :rules="{trigger: 'blur',}"
                >
                  <!--validator: $validator.isContainBlank-->
                  <el-input v-model="item.installLocation"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </ayl-main>
      <ayl-main title="其他信息(选填)" :isCollapse="false">-->
      <div class="header">其他信息 (选填)</div>
      <div class="p24px">
        <el-form-item label="主服务器地址" prop="deviceInfoVOIOV.serverAddress">
          <el-input v-model="form.deviceInfoVOIOV.serverAddress" maxlength="32"></el-input>
        </el-form-item>
        <el-form-item label="主服务器端口" prop="deviceInfoVOIOV.serverPort">
          <el-input v-model="form.deviceInfoVOIOV.serverPort" maxlength="4"></el-input>
        </el-form-item>
        <el-form-item label="备服务器地址" prop="deviceInfoVOIOV.alternateServerAddress">
          <el-input v-model="form.deviceInfoVOIOV.alternateServerAddress" maxlength="32"></el-input>
        </el-form-item>
        <el-form-item label="备服务器端口" prop="deviceInfoVOIOV.alternateServerPort">
          <el-input v-model="form.deviceInfoVOIOV.alternateServerPort" maxlength="4"></el-input>
        </el-form-item>
        <el-form-item label="音频编码格式">
          <el-select v-model="form.deviceInfoVOIOV.audioEncodeFormat" placeholder="请选择">
            <el-option
              v-for="(item, idx) in $enums.AudioEncode.list"
              :key="idx"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </div>
      <!-- </ayl-main> -->
    </el-form>
    <div class="tac mt40px">
      <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
      <el-button class="btn-hw" @click="onSubmitAndNext">保存并录入下一条</el-button>
      <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
    </div>
  </div>
</template>

<script>
//代表单个摄像头的json
const aisle = {
  pipeName: null,
  attachedType: null,
  status: null,
  physicalPipe: null,
  attachedModel: null,
  installLocation: null,
  attachedCompany: null,
};

export default {
  props: {
    dataFromDialog: {
      //从弹窗获得传来的参数等数据，！！！！名称固定！！！！
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    const validateDeviceName = (rule, value, callback) => {
      if (!value) return callback(new Error("请输入设备号"));
      if (value.length < 2 || !/^[0-9]*[1-9][0-9]*$/.test(value)) {
        callback(new Error("请输入正确的设备号"));
      } else {
        callback();
      }
    };

    return {
      nav: [{ name: "新增设备" }],
      debug: 0,
      submitLoading: false,
      bidList: [],
      protocolTypeList: [],
      companyList: [],
      companyName: null,
      company: null,
      pipeNumber: [],
      form: {
        deviceInfoVOIOV: {
          bidId: null,
          company: null, //所属公司
          //deviceId: null, //设备编号-后端生成的，不用前端输入
          deviceName: null, //设备号（名）
          deviceCompany: null, //设备厂家
          // productKey: null, //设备型号
          deviceType: null, //设备类型
          objectType: null, //设备应用对象:0-车辆、1-人员（手环）
          // simCardNumber: null,
          deviceModel: null, //产品型号
          audioEncodeFormat: null,
          deviceFunction: [],
          serverAddress: null,
          serverPort: null,
          alternateServerAddress: null,
          alternateServerPort: null,
          position: null,
          remark: null,
          protocolType: [],
          netProtocol: "TCP", //写死
          // pipeNumber: 0
        },
        deviceAttachedInfoVOIOVs: [],
      },
      rules: {
        "deviceInfoVOIOV.company": {
          min: 1,
          max: 20,
          required: true,
          message: "请输入1-20位所属公司名称",
          trigger: "blur",
        },
        "deviceInfoVOIOV.deviceName": {
          required: true,
          validator: validateDeviceName,
          trigger: "blur",
        },
        "deviceInfoVOIOV.deviceCompany": [
          { required: true, message: "请选择设备厂家", trigger: "change" },
        ],
        "deviceInfoVOIOV.deviceModel": {
          min: 1,
          max: 20,
          required: true,
          message: "请输入1-20位产品型号",
          trigger: "blur",
        },
        "deviceInfoVOIOV.deviceType": {
          required: true,
          message: "请选择设备类型",
          trigger: "change",
        },
        "deviceInfoVOIOV.objectType": {
          required: true,
          message: "请选择应用对象",
          trigger: "change",
        },
        "deviceInfoVOIOV.bidId": {
          required: true,
          message: "请选择所属标段",
          trigger: "change",
        },
      },
    };
  },

  watch: {
    pipeNumber: {
      handler(newName, oldName) {
        this.val_ = this.$_.differenceWith(oldName, newName, this.$_.isEqual);

        setTimeout(() => {
          oldName.forEach((e, i) => {
            if (this.val_[0] === e) {
              this.num = i;
            }
          });
        }, 50);
      },
    },
  },

  methods: {
    onChangeObjectType(val) {
      this.onChangeObject(val, false);
    },
    onChangeObject(val, holdProtocolType) {
      this.log(val, typeof val);
      if (!holdProtocolType) this.form.deviceInfoVOIOV.protocolType = []; //清除之前的选项
      if (val !== "")
        this.protocolTypeList =
          val === 1
            ? this.$enums_hw.protocolType.getListForPeople()
            : this.$enums_hw.protocolType.getListForCar();
      else this.protocolTypeList = [];
    },
    //获取所属标段
    hanldBidId(arr) {
      this.form.deviceInfoVOIOV.bidId = arr[1];
    },
    async submit() {
      let self = this;
      await self.$api_hw.equipmentManagement_insertDeviceInfo({
        deviceVOIOV: _fixData(self.form),
      });
      function _fixData(d) {
        // for (let key in d) {
        //   switch (key) {

        //   }
        // }
        (self.form.deviceInfoVOIOV.netProtocol = "TCP"), //写死
          (self.form.deviceInfoVOIOV.company = self.company);
        self.log(d);
        return d;
      }
    },
    //提交表单
    async onSubmit(isGoNext) {
      let self = this;
      if (self.submitLoading) return; //防止重复提交

      self.$refs.form.validate(async (valid) => {
        if (!valid) return;
        self.submitLoading = true;
        try {
          await self.submit();
          self.$notify({
            title: "成功",
            message: `${self.nav[0].name}成功`,
            type: "success",
          });
          BUS.$emit(BUSEVENT.REFRESH_EQUIPMENT_MANAGEMENT);
          if (isGoNext !== true) {
            self.onClose();
          } else {
            //reset
            //{protocolType:null}是为了解决：el-select多选模式下，数据更新导致下拉选项无法选择
            self.form = { deviceInfoVOIOV: {protocolType:null}, deviceAttachedInfoVOIOVs: [] };
            self.protocolTypeList = [];
            self.$refs["form"].resetFields();          }
        } catch (e) {} finally {
          self.submitLoading = false;
        }
      });
    },
    onSubmitAndNext() {
      this.onSubmit(true);
    },
    onClose() {
      this.$emit("onClose");
    },
    //获取摄像头信息
    onSelectCameraChange(val) {
      if (val.length === 0) {
        this.form.deviceAttachedInfoVOIOVs = [];
        return;
      }
      if (val.length > this.form.deviceAttachedInfoVOIOVs.length) {
        for (
          let i = 0;
          i < val.length - this.form.deviceAttachedInfoVOIOVs.length;
          i++
        ) {
          aisle.pipeName = val[this.form.deviceAttachedInfoVOIOVs.length];
          this.form.deviceAttachedInfoVOIOVs.push(this.$_.cloneDeep(aisle));
        }
      } else {
        setTimeout(() => {
          this.form.deviceAttachedInfoVOIOVs.splice(this.num, 1);
        }, 250);
      }
    },
  },
  async mounted() {
    this.$refs["form"].resetFields(); //防止el-select在多选模式的时候验证抽风
    this.bidList = await this.$api_hw.common_getBidNameAndIdList({});
    this.companyList = await this.$api_hw.common_getTenantCompanyInfo({});
    this.companyName = this.companyList.label;
    this.company = this.companyList.value;
    this.pipeNumber = this.form.deviceAttachedInfoVOIOVs.map((e) => e.pipeName);
    // const tenantIdData = this.companyList.filter(
    //   e => e.companyId === this.form.deviceInfoVOIOV.company
    // );
    // this.simCardNumberList = await this.$api.GetNotEnableSimNumber({
    //   tenantId: tenantIdData[0].tenantId
    // });
  },
};
</script>

<style lang='sass' scoped>
.clearBox
  padding: 4px 20px
  .aisle-form
    border: 1px solid #eeeeee
    margin-bottom: 8px
    padding: 0px 32px 10px 32px
    .aisle-title
      width: 100%
      padding: 24px 0 10px 0
      font-size: 16px
/deep/ .el-input--mini
      width: 170px
.p24px
  padding-top: 24px
.header
  border-left: 2px solid #1ABC9C
  height: 16px
  padding-left: 10px
  font-size: 16px
</style>
